import { NgModule } from '@angular/core';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from '@rle-environments/environment';

@NgModule({
	declarations: [],
	imports: [
		provideFirestore(() => {
			const firestore = getFirestore();
			if (environment.useDBEmulator) {
				console.log('Use DB Emulator');
				connectFirestoreEmulator(firestore, 'localhost', 8080);
			}
			return firestore;
		}),
		provideStorage(() => {
			const storage = getStorage();
			if (environment.useStorageEmulator) {
				console.log('Use Storage Emulator');
				connectStorageEmulator(storage, 'localhost', 9199);
			}
			return storage;
		}),
		provideFunctions(() => {
			const functions = getFunctions(undefined, 'europe-west3');
			if (environment.useFunctionsEmulator) {
				console.log('Use Functions Emulator');
				connectFunctionsEmulator(functions, 'localhost', 5001);
			}
			return functions;
		})
	]
})
export class ApiClientModule {}
