import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
	browserLocalPersistence,
	browserPopupRedirectResolver,
	browserSessionPersistence,
	connectAuthEmulator,
	initializeAuth,
	provideAuth
} from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

const providePersistence = () => {
	// If its running on desktop, use session (until browser closes) for better security, otherwise store it until
	// logout.
	return Capacitor.getPlatform() === 'web' ? browserSessionPersistence : browserLocalPersistence;
};

@NgModule({
	imports: [
		CommonModule,
		provideAuth(() => {
			const auth = initializeAuth(getApp(), {
				persistence: providePersistence(),
				popupRedirectResolver: Capacitor.isNativePlatform() ? undefined : browserPopupRedirectResolver
			});
			if (environment.useFunctionsEmulator) {
				console.log('Use Auth Emulator');
				connectAuthEmulator(auth, 'http://localhost:9099', {
					disableWarnings: true
				});
			}
			return auth;
		})
	]
})
export class AuthModule {}
