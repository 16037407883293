import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

export enum LANGUAGE {
	ENGLISH = 'en',
	GERMAN = 'de'
}

@Injectable({
	providedIn: 'root'
})
export class I18nService {
	public static readonly defaultLanguage = LANGUAGE.ENGLISH;
	public static readonly supportedLanguages = [
		LANGUAGE.ENGLISH,
		LANGUAGE.GERMAN
	];

	constructor(private translateService: TranslateService) {}

	public get(tag: string, params?: object): Promise<string> {
		return tag
			? firstValueFrom(this.translateService.get(tag, params))
			: Promise.resolve(tag);
	}

	public getInstant(
		tag: string,
		params?: object,
		alternateTag?: string
	): string {
		const t =
			alternateTag && !this.hasTranslation(tag) ? alternateTag : tag;
		return t ? this.translateService.instant(t, params) : t;
	}

	public getWithAlternateTag(tag: string, tag2: string): Promise<string> {
		const tagExist = this.hasTranslation(tag);
		return this.get(tagExist ? tag : tag2);
	}

	public getCurrentLanguage(): string {
		return this.translateService.currentLang;
	}

	public initTranslate(): Promise<void> {
		// this language will be used as a fallback when a translation isn't found in the current language
		this.translateService.setDefaultLang(I18nService.defaultLanguage);

		return this.setLanguageByDevice();
	}

	public async setLanguageByDevice(): Promise<void> {
		const deviceLanguage =
			(await Device.getLanguageCode())?.value === 'de' ? 'de' : 'en';

		if (this.translateService.currentLang !== deviceLanguage) {
			this.translateService.use(deviceLanguage);
		}
	}

	public hasTranslation(tag: string): boolean {
		if (!tag) {
			return false;
		}
		const translation = this.translateService.instant(tag);
		return translation !== tag && translation !== '';
	}
}
